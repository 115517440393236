'use client';

import usePreviewId from '@haaretz/s-atoms/previewId';
import useRenderingKind from '@haaretz/s-atoms/renderingKind';
import useUser from '@haaretz/s-atoms/user';
import { usePathname } from 'next/navigation';
import { createContext, useContext, useEffect, useState } from 'react';

import useIsRainbowEnabled from '../../utils/useIsRainbowEnabled';

import { DataProvider } from './data/DataProvider';
import { FactsProvider } from './facts/FactsProvider';
import { SlotsProvider } from './slots/SlotsProvider';

import type { RainbowToolFragment } from '@haaretz/s-fragments/RainbowTool';

interface RainbowProviderProps {
  useDefaultOnlyForAnonymous?: boolean;
  defaultRainbowTools?: RainbowToolFragment[] | null;
  defaultRainbowToolsTimeout?: number | null;
  children: React.ReactNode;
}

type RainbowAvailabilityStatus = 'missing' | 'enabled' | 'disabled';

const RainbowAvailableContext = createContext<RainbowAvailabilityStatus>('missing');

export default function RainbowProvider({
  useDefaultOnlyForAnonymous = false,
  defaultRainbowTools = [],
  defaultRainbowToolsTimeout,
  children,
}: RainbowProviderProps) {
  const previewId = usePreviewId();
  const pathname = usePathname();
  const isRainbowEnabled = useIsRainbowEnabled();
  const renderingKind = useRenderingKind();
  const isBot = renderingKind === 'expanded';
  const user = useUser('cookieValue');

  const [rainbowAvailability, setRainbowAvailability] =
    useState<RainbowAvailabilityStatus>('disabled');

  useEffect(() => {
    const enableRainbow =
      !previewId && isRainbowEnabled && !isBot && !!user.anonymousId && !!user.userType;

    setRainbowAvailability(enableRainbow ? 'enabled' : 'disabled');
  }, [isBot, isRainbowEnabled, previewId, user.anonymousId, user.userType]);

  return (
    <RainbowAvailableContext.Provider value={rainbowAvailability}>
      <SlotsProvider>
        <FactsProvider>
          <DataProvider
            invalidateProp={pathname}
            useDefaultOnlyForAnonymous={useDefaultOnlyForAnonymous}
            defaultRainbowTools={defaultRainbowTools}
            defaultRainbowToolsTimeout={defaultRainbowToolsTimeout}
          >
            {children}
          </DataProvider>
        </FactsProvider>
      </SlotsProvider>
    </RainbowAvailableContext.Provider>
  );
}

export function useRainbowAvailableStatus() {
  return useContext(RainbowAvailableContext);
}
